<template>
  <div class="sea-res" v-loading="refresh" :key="indexKey">
    <h4>
      {{!!searchAtMe.id ?  searchAtMe.name: titleMap[typeTitle]}}搜索结果
      {{typeTitle === '3' ? `(${total}人)` : typeTitle === '5' ? `(${allNoticeCount}条)` :''}}
    </h4>
    <el-card class="sear-nav" v-if="typeTitle === '1' || typeTitle === '2'">
      <div class="sear-blogs">
        <!-- 搜索微博 -->
        <template v-if="typeTitle === '1'">
          <ul>
            <li
              @click="switchType(index, 'blog')"
              :class="{ 'search-nav-active': activeIndex === index }"
              v-for="(item, index) in blog"
              :key="index + 1"
            >
              <svg
                v-if="index === 0"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.206 6.077L10 2.155l6.794 3.922v7.846L10 17.845l-6.794-3.922V6.077zM6 7l4 3m0 0v5m0-5l4-3"
                  stroke="#999"
                  stroke-width="2"
                />
              </svg>
              <svg
                class="fill-secend"
                v-else-if="index === 1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.651 2.49l-1.44 1.44A5.996 5.996 0 0 1 16 9c0 2.17-1.151 4.07-2.876 5.124l1.444 1.444A7.991 7.991 0 0 0 18 9a7.99 7.99 0 0 0-3.349-6.51zM2 9a7.99 7.99 0 0 1 3.348-6.51l1.44 1.44A5.996 5.996 0 0 0 4 9c0 2.17 1.151 4.07 2.876 5.124l-1.444 1.444A7.99 7.99 0 0 1 2 9z"
                  fill="#999999"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 9a4.002 4.002 0 0 1-3.008 3.876c.005.04.008.082.008.124v4a1 1 0 1 1-2 0v-4c0-.042.003-.083.008-.124A4.002 4.002 0 0 1 10 5a4 4 0 0 1 4 4zm-4 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                  fill="#999999"
                />
              </svg>
              <svg
                v-else-if="index === 2"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3"
                  y="3"
                  width="14"
                  height="14"
                  rx="1"
                  stroke="#999"
                  stroke-width="2"
                />
                <rect
                  x="5.5"
                  y="6.5"
                  width="5"
                  height="1"
                  rx=".5"
                  stroke="#999"
                />
                <rect
                  x="5.5"
                  y="10.5"
                  width="7"
                  height="1"
                  rx=".5"
                  stroke="#999"
                />
              </svg>
              {{ item }}
              ({{ allBlogsCount && allBlogsCount[index] }})
            </li>
          </ul>
        </template>
        <!-- 搜索帖子 -->
        <template v-else-if="typeTitle === '2'">
          <ul class="forum-nav">
            <li
              v-for="({id,name,count}, index) in allFormCount"
              :key="index + 1"
              @click="switchType(id)"
              :class="{ 'search-nav-active': activeIndex === id }"
            >
              {{ name }}&nbsp;&nbsp;({{ count }})
            </li>
          </ul>
        </template>
      </div>
    </el-card>
    <div
      v-infinite-scroll="load"
      infinite-scroll-immediate="false"
      infinite-scroll-distance="0"
    >
      <div class="sear-conetnt" v-if="hasData">
        <!-- 搜索动态结果 -->
        <template v-if="typeTitle === '1'">
          <BlogsList
            v-if="dataList.length > 0"
            :feedList="dataList"
            @likeFeed="updateFeedInfo($event, 1, dataList)"
            @collectFeed="updateFeedInfo ($event, 2, dataList)"
            @deleteFeed="deleteFeed"
          ></BlogsList>
        </template>
        <!-- 搜索帖子结果 -->
        <template v-else-if="typeTitle === '2'">
          <el-card>
            <ul class="forumLi-wrapper" v-if="dataList.length > 0">
              <li class="forum-li" v-for="item in dataList" :key="item.id">
                <!-- 头像 -->
                <div>
                  <UserAvatar
                    :user="item.user"
                    size="38"
                    fontSize="14"
                    medalSize="58"
                  ></UserAvatar>
                  <span class="oneline-overflow">{{ item.user.name }}</span>
                </div>
                <!-- 标题和时间 -->
                <div>
                  <span
                    class="oneline-overflow res-forum-title"
                    @click="
                      router.push({
                        name: 'articalDetail',
                        params: { id: item.id }
                      })
                    "
                    v-html="item.title && formatHtml(item.title)"
                  ></span>
                  <span class="created-at">
                    {{ formatDate(item.created_at) }}
                  </span>
                </div>
                <!-- 回复数 -->
                <div class="reply-counts">
                  <span>回复:&nbsp; {{ item.comment_count }}</span>
                </div>
              </li>
            </ul>
          </el-card>
        </template>
        <!-- 用户结果 -->
        <template v-else-if="typeTitle === '3'">
          <el-card>
            <ul class="user-wrapper">
              <li v-for="item in userList" :key="item.id">
                <UserAvatar :user="item" size="60" fontSize="24" medalSize="90"></UserAvatar>
                <div class="message">
                  <span
                    class="user-name"
                    @click="
                      router.push({
                        name: 'personalCenter',
                        query: { id: item.id }
                      })
                    "
                    >{{ item.name }}</span
                  >
                  <span class="blog-account">动态：{{ item.feed_count }}</span>
                  <span class="forum-account">帖子：{{ item.post_count }}</span>
                </div>
              </li>
            </ul>
            <div class="pagination">
              <el-button type="text" @click="jumpFirstPage" class="my-btn"
                >首页</el-button
              >
              <el-pagination
                ref="pagination"
                layout="pager"
                v-model:total="total"
                v-model:current-page="currentPage"
                v-model:page-size="pagesize"
                @current-change="changePage"
              >
              </el-pagination>
              <el-button
                type="text"
                @click="jumpLastPage"
                class="my-btn"
                style="margin: 0px 5px"
                >尾页</el-button
              >
            </div>
          </el-card>
        </template>
        <!-- 公告结果 -->
        <template v-else-if="typeTitle === '5'">
            <AnnouncementList :data="dataList"/>
        </template>
        <template v-else-if="typeTitle === '0'">
          <Notification :isLeftVisible="false"/>
        </template>
      </div>
      <template v-else>
        <!-- 无帖子和动态或者公告 -->
        <empty-data
          v-if="!refresh &&
          ((typeTitle === '1' || typeTitle === '2' ) && dataList.length == 0)
          || (dataList.length == 0 && typeTitle === '5') "
          image="no-search-result"
          description="暂无搜索结果"
          descriptionTwo="换个关键词试试吧"
        ></empty-data>
        <!-- 无用户 -->
        <empty-data
          v-else-if="!refresh && userList.length == 0 && typeTitle == '3'"
          image="no-search-user"
          description="查无此人"
          descriptionTwo="换个关键词试试吧"
        ></empty-data>
      </template>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { formatDate } from '@/utils/filterHtml.js'
import AnnouncementList from '@/views/layout/announcement/component/announcementList.vue'
import Notification from '@/views/layout/notification/index.vue'
import BlogsList from '@/components/blog/BlogsList.vue'
import { updateFeedInfo } from '@/utils/userCenter.js'

export default {
  components: {
    AnnouncementList,
    Notification,
    BlogsList
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      keyword: route.query.keyword,
      typeTitle: route.query.type,
      titleMap: {
        1: '微博',
        2: '论坛',
        3: '用户',
        5: '公告',
        0: '@我'
      },
      activeIndex: 0,
      indexKey: 0,
      dataList: computed(() => store.state.searchRes.searchResList),
      pages: computed(() => store.state.searchRes.page),
      userList: computed(() => store.state.searchRes.userList),
      allBlogsCount: computed(() => store.state.searchRes.blogsCounts),
      allFormCount: computed(() => store.state.searchRes.forumCounts),
      allNoticeCount: computed(() => store.state.searchRes.noticeCounts),
      blog: ['全部', '动态', '资讯'],
      refresh: computed(() => store.state.searchRes.refreshValue),
      searchAtMe: computed(() => store.state.searchRes.searchAtMe)
    })

    const hasData = computed(() => {
      let flag = false
      if (state.typeTitle === '0') { // 搜索@我，不是当前页面，默认有数据
        flag = true
      } else if (state.typeTitle !== '3' && state.dataList.length > 0) {
        flag = true
      } else if (state.typeTitle === '3' && state.userList.length > 0) {
        flag = true
      }
      return flag
    })

    // 搜列筛选列表
    store.dispatch('forum/getCatalists')

    // 切换分类 二级导航
    function switchType (index) {
      store.commit('setNoMore', false)
      store.commit('setLoadMore', false)
      store.commit('searchRes/setPage', 1)
      // 切换类名
      state.activeIndex = index
      queryData()
    }

    // 触底加载
    function load () {
      if (hasData.value && !store.state.noMore && !store.state.loadMore) {
        store.commit('setLoadMore', true)
        store.commit('searchRes/setPage', ++state.pages)
        queryData()
      }
    }
    function queryData () {
      const data = {
        type: Number(state.typeTitle), // 搜索类型
        page: state.pages, // 页码数
        keyword: state.keyword
      }
      // 触底加载
      if (state.typeTitle === '1') {
        data.blogType = state.activeIndex
      } else if (state.typeTitle === '2') {
        data.cateId = state.activeIndex
      }
      store.dispatch('searchRes/getSearchRes', data)
    }

    // 监听type的变化
    watch(
      () => route.query.keyword,
      (newValue, oldValue) => {
        state.keyword = newValue
        pageInit()
      }
    )
    // 监听关键字变化
    watch(
      () => route.query.type,
      (newValue, oldValue) => {
        state.typeTitle = newValue
        pageInit()
      }
    )

    // 初始化页面
    function pageInit () {
      state.activeIndex = 0
      store.commit('searchRes/setPage', 1)
      // 查询@我的，开启loading效果
      // if (route.query.type === '0') {
      //   store.commit('searchRes/setRefreshValue', true)
      // }
      queryData()
    }

    // 分页插件
    const paginationPage = reactive({
      total: computed(() => store.state.searchRes.userCounts || 0),
      currentPage: 1, // 当前页数
      pagesize: 28 // 显示条数
    })
    const pagination = ref(null)
    function getUserData () {
      store.dispatch('searchRes/getUserList', {
        page: paginationPage.currentPage,
        keyword: state.keyword
      })
    }
    // 分页
    function changePage () {
      getUserData()
    }
    // 首尾页跳转
    function jumpFirstPage () {
      paginationPage.currentPage = 1
      getUserData()
    }
    function jumpLastPage () {
      paginationPage.currentPage = Math.ceil(
        paginationPage.total / paginationPage.pagesize
      )
      getUserData()
    }
    // 删除
    function deleteFeed (id) {
      const index = state.dataList.findIndex((item) => item.id === id)
      state.dataList.splice(index, 1)
    }

    const toUserPage = (id) => {
      router.push({ name: 'personalCenter', query: { id } })
    }

    onMounted(() => {
      state.typeTitle = route.query.type
      state.keyword = route.query.keyword
      pageInit()
    })
    return {
      router,
      ...toRefs(state),
      ...toRefs(paginationPage),
      switchType,
      load,
      updateFeedInfo,
      changePage,
      deleteFeed,
      toUserPage,
      hasData,
      jumpFirstPage,
      jumpLastPage,
      pagination,
      formatDate
    }
  }
}
</script>

<style lang="less">
.sea-res {
  width: 791px;
  margin: 0 auto;
  // 搜索结果
  h4 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  .el-card {
    border-radius: 6px;
  }
  .el-card__body {
    padding: 0;
  }
  // 搜索导航
  .sear-nav {
    margin-bottom: 8px;
    .sear-blogs {
      font-size: 14px;
      display: flex;
      align-items: center;
      ul {
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-left: 5px;
        flex-wrap: wrap;
        li {
          padding: 0 10px 0 10px;
          margin: 0 4px;
          margin-bottom: 8px;
          height: 30px;
          line-height: 30px;
          display: flex;
          align-items: center;
          border-radius: 28px;
          color: @other-text-color;
          svg {
            margin-right: 5px;
          }
          &:hover {
            cursor: pointer;
            background: #f1f1fd;
            color: #7972f0;
            svg circle,
            svg path,
            svg rect {
              stroke: @active-text-color;
            }
            .fill-secend circle,
            .fill-secend path,
            .fill-secend rect {
              fill: @active-text-color;
              stroke: transparent;
            }
          }
        }
      }

      .forum-nav {
        li {
          padding: 0 10px;
          text-align: center;
        }
      }
    }
  }
  .blog-tpl li {
    border-radius: 6px;
    .user-name,
    .blog-content {
      color: @default-text-color;
    }
  }
  .blog-tpl .blog-message .userInfo {
    width: 900px;
  }
  .search-nav-active {
    font-weight: bold;
    color: #7972f0 !important;
    .fill-secend circle,
    .fill-secend path,
    .fill-secend rect {
      fill: @active-text-color;
      stroke: transparent;
    }
    svg circle,
    svg path,
    svg rect {
      stroke: @active-text-color;
    }
  }
  .forumLi-wrapper {
    .forum-li {
      display: flex;
      align-items: flex-start;
      // height: 89px;
      padding: 15px 22px;
      border-bottom: 1px solid #f1f1f1;
      font-size: 12px;
      & div:first-child {
        color: @default-text-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 38px;
        // height: 38px;
        img {
          height: 38px;
        }
        margin-right: 15px;
        line-height: 18px;
        span {
          text-align: center;
          margin-top: 5px;
          width: 36px;
        }
        div {
          margin-right: 0;
        }
      }
      & div:nth-child(2) {
        width: 635px;
        font-size: 14px;
        display: flex;
        flex-direction: column;

        .res-forum-title {
          margin-top: 9px;
          line-height: 18px;
          width: fit-content;
          color: @default-text-color;
          &:hover {
            cursor: pointer;
          }
        }
        .created-at {
          margin-top: 8px;
          font-size: 12px;
          color: @second-text-color;
        }
      }
      & div:last-child {
        margin-top: 9px;
        flex: 1;
      }
    }
  }
  .el-card {
  .user-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 37px 0 47px;
    li {
      display: flex;
      width: 25%;
      margin-bottom: 32px;
      .user-avatar {
        margin-right: 16px;
      }
      div {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #777777;
        .user-name {
          color: #000000;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 4px;
          margin-top: 3px;
          &:hover {
            cursor: pointer;
          }
        }
        .blog-account,
        .forum-account {
          font-size: 12px;
          line-height: 16px;
          color: @second-text-color;
        }
      }
    }
  }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 28px;
    margin-right: 43px;
    margin-top: -4px;
    .my-btn {
      color: @default-text-color;
    }
    .el-pager {
      li {
        width: 18px !important;
        height: 18px !important;
        line-height: 18px !important;
        margin: 0 4px;
        min-width: 18px;
        &:hover {
          color: @active-text-color;
          background: #f1f1fd;
          border-radius: 9px;
        }
      }
      .active {
        color: @active-text-color;
      }
    }
  }
  .el-empty {
    border-radius: 6px;
    border: 1px solid #f1f1f1;
    padding-top: 230px;
    padding-bottom: 301px;
    .el-empty__image {
      width: 90px;
      height: 94px;
    }
    p {
      font-size: 18px;
    }
    .el-empty__bottom {
      font-size: 14px;
      margin-top: 0;
    }
  }
}
</style>
